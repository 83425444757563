<template>
	<span>
		<span v-if="answer">
			{{ answer }}
		</span>
	</span>
</template>

<script>
export default {
	name: "QuestionCell",
	props: ["item", "column"],
	computed: {
		value() {
			return this.item[this.column.value];
		},
		question() {
			return this.$store.state.questions.data[this.column.question];
		},
		answer_options() {
			return this.question.answer_options;
		},
		answer() {
			let value = this.value; 
            if( !value ){
                return;
            }

			if (["comment", "input"].includes(this.question.type)) {
                return value
				// return this.mwutils.prettyPrint(this.value, "SENTENCE");
			}
			if (["tiles", "checkboxes"].includes(this.question.type)) {
				if (this.question.multiple) {
					if( typeof value == "string" ){
						value = [value];
					}
					return value.map((v) => {
						return this.answer_options[v]
							? this.answer_options[v].text
							: v;
					}).join(", ");
				}
				return this.answer_options[value]
					? this.answer_options[value].text
					: value;
			}
			return value;
		},
	},
};
</script>
